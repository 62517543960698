.pageHeaderCard {
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.linkStyle {
  color: white;
  text-decoration: none;
}
.linkIcon {
  font-size: 2.5rem !important;
  margin-left: 10px;
}
