.appContent {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.alignCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
