.chartContainer {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.chart {
  align-self: center;
}
