body {
  font-family: Arial, Helvetica, sans-serif;
  background-color: pink;
  height: 1000px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttons {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  align-items: center;
}
.yes {
  background-color: rgb(164, 228, 164);
  cursor: pointer;
}
.no {
  background-color: rgb(196, 135, 135);
  cursor: not-allowed;
}
button {
  height: 50px;
  width: 100px;
  border-radius: 5px;
}
.question {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.question > p {
  font-size: 40px;
}
img {
  width: 200px !important;
}
